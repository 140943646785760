import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./concurso-publico-pss.css";

const concurso = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <p className="titulo">
        CONCURSO PÚBLICO E PROCESSO SELETIVO SIMPLIFICADO
      </p>
    </Container>
    <Container>
      <Card
        style={{
          marginRight: "50px",
          boxShadow: "0 0 0 0",
          border: "0 none",
          outline: "0",
        }}
        className="concurso-card"
      >
	  
        <Row>
          <p className="titulo">Editais</p>
		
		<hr />
		  <h3>Processo Seletivo Simplificado - Docente</h3>
		  <br /><br /><br />
		  
		  <h5>PSS nº 146/07/2024:  Economia e Finanças</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 25-09-2024 - Edital de abertura de inscrição PSS 146-07-2024 Economia e Finanças - Curso ADS.pdf"
          >
            - Edital de abertura - Disciplina: Economia e Finanças (02 horas-aula, período matutino, Curso de Análise e Desenvolvimento de Sistemas)
          </a>
		  <br />
		  <br />
		  <br />
		  
		  <h5>PSS nº 146/06/2024: Laboratório de Desenvolvimento WEB</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 29-08-2024 - Edital de abertura de inscrição PSS 146-06-2024 Laboratório de Desenvolvimento WEB.pdf"
          >
            - Edital de abertura - Disciplina: Laboratório de Desenvolvimento WEB (04 horas-aula, período matutino, Curso de Desenvolvimento de Software Multiplataforma)
          </a>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 07-10-2024 - Resultado de Memorial e Classificação Final - Edital PSS 146-06-2024 - Lab de Des WEB.pdf"
          >
            - Edital de Resultado e Classificação Final - Disciplina: Laboratório de Desenvolvimento WEB (04 horas-aula, período matutino, Curso de Desenvolvimento de Software Multiplataforma)
          </a>
		  
		  <br />
		  <br />
		  <br />
		  
		  <h5>PSS nº 146/05/2024:  Inovação e Fabricação Digital</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 22-07-2024 - Edital de abertura de inscrição PSS 146-05-2024 Inovação e Fabricação Digital.pdf"
          >
            - Edital de abertura - Disciplina: Inovação e Fabricação Digital (02 horas-aula, período matutino, Curso de  Manufatura Avançada)
          </a>
		  
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 11-09-2024 - Edital de Convocação - PSS 146-05-2024.pdf"
          >
            - Edital de Convocação - Disciplina: Inovação e Fabricação Digital (02 horas-aula, período matutino, Curso de  Manufatura Avançada)
          </a>
		  <br />
		  <br />
		  <br />
		  
		  <h5>PSS nº 146/04/2024:  Tecnologia da Informação Aplicada à Logística</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 22-07-2024 - Edital de abertura de inscrição PSS 146-04-2024 Tec da Inf Aplicada à Logística.pdf"
          >
            - Edital de abertura - Disciplina: Tecnologia da Informação Aplicada à Logística (02 horas-aula, período noturno, Curso de  Logística)
          </a>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 13-09-2024 - Edital de Resultado e Classificação Final PSS 146-04-2024.pdf"
          >
            - Edital de Resultado e Classificação Final - Disciplina: Tecnologia da Informação Aplicada à Logística (02 horas-aula, período noturno, Curso de  Logística)
          </a>
		  
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/DOE 30-09-2024 - Edital de Convocação nº 01-2024 PSS 146-04-2024.pdf"
          >
            - Edital de Convocação - Disciplina: Tecnologia da Informação Aplicada à Logística (02 horas-aula, período noturno, Curso de  Logística)
          </a>
		  <br />
		  <br />
		  <br />
		  
		</Row>
		
		<br />
		<br />
		<br />
		<Row>
		  <hr />
		  <hr />
		  <p className="titulo">Formas de Contratação</p>
          <p id="subtitulo">Docentes e Auxiliares de Docente</p>
          <p id="espaco">
            A contratação de docentes pode ocorrer de duas formas: por processo
            seletivo, quando a contratação é feita por prazo determinado
            (contrata-se por 1 ano, podendo ser prorrogado por mais 1 ano) e por
            concurso público, quando a contratação é feita por tempo
            indeterminado.
          </p>
		  
          <p id="subtitulo">Funcionários de áreas administrativas</p>
          <p id="espaco">
            A contratação de funcionários de áreas administrativas é feita somente através de concurso público.{" "}
          </p>
		  
		  <hr />
          <p id="subtitulo">Informações</p>
          <p>
            Para detalhes sobre a abertura de concursos, o candidato deverá
            contatar a Unidade de interesse pessoalmente, pelos telefones
            indicados no site ou nas seguintes páginas:{" "}
          </p>
		  
          <p>Concursos para contratação de docentes e auxiliares de docente do Centro Paula Souza</p>
          <a
            id="left"
            target="blank"
            href="https://www.cps.sp.gov.br/concursopublico/"
          >
            https://www.cps.sp.gov.br/concursopublico/
          </a>
		  
          <p> Concursos públicos do Estado de São Paulo</p>
		  <a
            id="left"
            target="blank"
            href="http://www.concursopublico.sp.gov.br"
          >
            http://www.concursopublico.sp.gov.br
          </a>
		  
          <p> Diário Oficial do Estado de São Paulo</p>
          <a
			id="left"
			target="blank"
			href="https://doe.sp.gov.br/"
		  >
            https://doe.sp.gov.br/
          </a>
		  
          <p id="subtitulo">Deliberações</p>
          <p>
            Dispõe sobre norma para a realização de Concurso Público para o
            preenchimento de emprego público permanente de Professor do Ensino
            Superior das Faculdades de Tecnologia do Centro Estadual de Educação
            Tecnológica Paula Souza.
          </p>
          <a
            id="left"
            target="blank"
            href="http://fatecsjc.edu.br/downloads/arquivos/2015-1/2015.01.09_deliberao_ceeteps_009.pdf"
          >
            Deliberação CEETEPS nº 009 de 09/01/2015
          </a>
        </Row>
      </Card>
    </Container>
    <Footer />
  </App>
);

export default concurso;
