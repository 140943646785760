import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./styles.module.css";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/esm/Carousel";
import { CarouselItem } from "react-bootstrap";

/*import vestibular from "../../components/images/carrossel/2024-2_vestibular.png";*/
/*import monitoria from "../../components/images/carrossel/monitoria_fatec.png";*/
import horariosaula from "../../components/images/carrossel/2024-2_horarios_aula.jpg";
import nube from "../../components/images/carrossel/nube_cursosead.png";
import cdio from "../../components/images/carrossel/CDIO_fatecsjc.png";
{
  /*---exemplo de import de imagem de fora do instagram */
}

interface IFeedItem {
  id: string;
  media_type: "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM";
  media_url: string;
  permalink: string;
  caption: string;
}

export function Instafeed() {
  const [feedList, setFeedList] = useState<IFeedItem[]>([]);
  const [index, setIndex] = useState(0);

  let handleSelect = (selectedIndex: React.SetStateAction<number>, e: any) => {
    setIndex(selectedIndex);
  };

  {
    /* lista para carrossel manual */
  }
  const manualList = [
	
	/*{
      img: monitoria,
      link: "https://sjc.fatec.sp.gov.br/downloads/monitoria/2024/Edital Interno 03 do Processo Seletivo para o Programa de Monitoria -2024-2.pdf",
      legenda: "Inscrições de 12/07/2024 a 17/07/2024, das 08h00 às 23h00",
    },*/
	/*{
      img: vestibular,
      link: "https://www.vestibularfatec.com.br/home/",
      legenda: "15/07/2024, a partir das 15h: divulgação da lista de classificação geral",
    },*/
	{
      img: horariosaula,
      link: "horarios-de-aula",
      legenda: "",
    },
    {
      img: nube,
      link: "https://www.nube.com.br/ead",
      legenda: "Nube - Curso EAD",
    },
    {
      img: cdio,
      link: "",
      legenda: "CDIO - Curso EAD",
    },
  ];

  async function getInstaFeed() {
    const token = process.env.REACT_APP_INSTA_TOKEN;
    const fields = "media_url,media_type,permalink,caption";
    const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`;
    const { data } = await axios.get(url);
    setFeedList(data.data);
  }

  useEffect(() => {
    getInstaFeed();
  }, []);

  return (
    <section className={styles.container}>
      <Carousel
        className={styles.carousel}
        variant="dark"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {/* exemplo de inclusão de imagem de fora no carrossel 
        -------------------------------------------------------*/}
        {manualList.map((item) => (
          <Carousel.Item interval={5000}>
            <a href={item.link} target="_blank" rel="noopener noreferrer" className={styles.item}>
              <Image
                className={styles.image}
                thumbnail
                width={500}
                height={300}
                src={item.img}
              />
            </a>
            <Carousel.Caption>
              <h3>{item.legenda}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}

        {/* fatiamento para limitar o número de posts do instagram exibidos até o 10  
        ----------------------------------------------------------------
        {feedList.slice(0, 10).map((item) => (
          <Carousel.Item interval={5000}>
            <a
              key={item.id}
              href={item.permalink}
              target="_blank"
              className={styles.item}
            >
              {item.media_type === "IMAGE" ||
              item.media_type === "CAROUSEL_ALBUM" ? (
                <Image
                  className={styles.image}
                  thumbnail
                  width={500}
                  height={300}
                  src={item.media_url}
                />
              ) : (
                <video controls controlsList="nofullscreen" width={250}>
                  <source src={item.media_url} />
                </video>
              )}
            </a>
            <Carousel.Caption>
              <h3>{item.caption}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}*/}

        {/* fatiamento ignorando a postagem número 5 por exemplo
        -----------------------------------------------------------
        {feedList.slice(0, 4).map((item) => (
          <Carousel.Item interval={5000}>
            <a
              key={item.id}
              href={item.permalink}
              target="_blank"
              className={styles.item}
            >
              {item.media_type === "IMAGE" ||
              item.media_type === "CAROUSEL_ALBUM" ? (
                <Image
                  className={styles.image}
                  thumbnail
                  width={500}
                  height={300}
                  src={item.media_url}
                />
              ) : (
                <video controls controlsList="nofullscreen" width={250}>
                  <source src={item.media_url} />
                </video>
              )}
            </a>
            <Carousel.Caption>
              <h3>{item.caption}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
        {feedList.slice(5, 10).map((item) => (
          <Carousel.Item interval={5000}>
            <a
              key={item.id}
              href={item.permalink}
              target="_blank"
              className={styles.item}
            >
              {item.media_type === "IMAGE" ||
              item.media_type === "CAROUSEL_ALBUM" ? (
                <Image
                  className={styles.image}
                  thumbnail
                  width={500}
                  height={300}
                  src={item.media_url}
                />
              ) : (
                <video controls controlsList="nofullscreen" width={250}>
                  <source src={item.media_url} />
                </video>
              )}
            </a>
            <Carousel.Caption>
              <h3>{item.caption}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}*/}
      </Carousel>
    </section>
  );
}

export default Instafeed;
